import React from 'react'
import splitbean from '../pics/splitBean.jpg'
import barcelove from '../pics/barcelove.png'
import eightytwenty from '../pics/80-20.jpg'

let JSProjectsExamples = () => (
    <article className='transparentContainer'>
        <h2>Bootcamp's projects examples done by our previous students</h2>
        <div className='flexContent'>
        <div className='whiteCard flex225'>
            <a href='https://projects.barcelonacodeschool.com/barcelove' target='_blank' rel='noopener noreferrer'>
                <p>Barcelove</p>
                <p>Explore Barcelona</p>
                <img src={barcelove} alt='Barcelove' />
            </a>
        </div>
        <div className='whiteCard flex225'>
            <a href='https://apps.apple.com/us/app/80-20/id6449088751' target='_blank' rel='noopener noreferrer'>
                <p>80/20 Weekly Tracker</p>
                <p>Healthy eating habits app</p>
                <img src={eightytwenty} alt='80/20 Weekly Tracker' />
            </a>
        </div>
        {/* <div className='whiteCard flex225'>
            <a href='https://projects.barcelonacodeschool.com/splitbeans' target='_blank' rel='noopener noreferrer'>
                <p>Split Bean</p>
                <p>Coffee e-commerce</p>
                <img src={splitbean} alt='Coffee e-commerce' />
            </a>
        </div> */}
        <h3 className='noMarginTop'><a href='https://projects.barcelonacodeschool.com/?projects=developers' target='_blank' rel='noopener noreferrer'>See more projects in the showcase...</a></h3>
        </div>
    </article>
)

export default JSProjectsExamples