import React from 'react'
import coursereport from "../pics/BestOnlineBadge2020.png"
import switchup from "../pics/switchup2020.png"

import Ratings from './Ratings'

export default function Badges({ activeLinks = true }) {



	return activeLinks ? (
		<div className="flexOneRow" style={{ 'width': '100%', 'paddingBottom': '1em', 'flexWrap': 'wrap' }}>
			<a target='_blank' href="https://www.coursereport.com/schools/barcelona-code-school#/reviews">
				<img
					className="badge"
					style={{
						// maxHeight: "300px",
						// maxWidth: "200px",
						display: "block",
						margin: "1em auto",
						flex: '1 1 250px'
					}}
					src={coursereport}
					alt="barcelona code school reviews on coursereport.com"
				/>
			</a>
			<a target='_blank' href="https://www.switchup.org/bootcamps/barcelona-code-school">
				<img
					className="badge"
					style={{
						// maxHeight: "300px",
						// maxWidth: "200px",
						display: "block",
						margin: "1em auto",
						flex: '1 1 250px'
					}}
					src={switchup}
					alt="barcelona code school reviews on switchup"
				/>
			</a>
		</div>
	) : (
		<div className="flexOneRow" style={{ 'width': '100%', 'flexWrap': 'wrap' }}>
			<img
				className="badge"
				style={{
					maxHeight: "300px",
					maxWidth: "200px",
					display: "block",
					margin: "1em auto",
					flex: '1 1 250px'
				}}
				src={coursereport}
				alt="barcelona code school reviews on coursereport.com"
			/>
			<img
				className="badge"
				style={{
					maxHeight: "300px",
					maxWidth: "200px",
					display: "block",
					margin: "1em auto",
					flex: '1 1 250px'
				}}
				src={switchup}
				alt="barcelona code school reviews on switchup"
			/>
	<Ratings/>
		</div>
	)
}

